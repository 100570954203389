import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { repositoryConfigs } from '../../utils/prismic-previews';
import { Layout } from '../../components/global/layout';
import { SliceZones } from '../../components/slices/slice-zones';
import { PreregisterForm } from '../../components/forms/preregister/preregister';

const PreregisterThankYouAssociates = ({ data, pageContext }) => {
    if (!data) return null;

    const document = data.prismicPreregister;

    return (
        <Layout
            pageTitle={document.data.title.text}
            metaTitle={document.data.meta_title.text}
            metaDescription={
                document.data.meta_description.text ||
                document.data.summary.text
            }
            shareImage={document.data.share_image.url}
        >
            <PreregisterForm
                subtitle={document.data.subtitle.text}
                title={document.data.title.text}
                summary={document.data.summary.text}
                image={document.data.form_image.url}
                isThankYouPage={true}
            />
            <SliceZones slices={document.data.body} />
        </Layout>
    );
};

export const query = graphql`
    query PreregisterAssociatesQuery {
        prismicPreregister {
            _previewable
            data {
                title {
                    text
                }
                summary {
                    text
                }
                subtitle {
                    text
                }
                form_image {
                    url
                }
                meta_title {
                    text
                }
                meta_description {
                    text
                }
                share_image {
                    url
                }
                body {
                    ... on PrismicPreregisterDataBodyLogos {
                        id
                        items {
                            logos_image {
                                alt
                                copyright
                                url
                                gatsbyImageData
                            }
                            logos_name {
                                text
                                html
                                richText
                            }
                        }
                        primary {
                            logos_title {
                                text
                            }
                            logos_background_image {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyContentWithMedia {
                        id
                        primary {
                            contentmedia_subtitle {
                                text
                            }
                            contentmedia_title {
                                text
                            }
                            contentmedia_content {
                                text
                            }
                            contentmedia_image {
                                url
                            }
                            contentmedia_button_text {
                                text
                            }
                            contentmedia_button_link {
                                url
                            }
                            contentmedia_button2_text {
                                text
                            }
                            contentmedia_button2_link {
                                url
                            }
                            contentmedia_image_alignment
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyRichTextContent {
                        id
                        primary {
                            richtextcontent_content {
                                richText
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyTestimonial {
                        id
                        primary {
                            testimonial_quote {
                                text
                            }
                            testimonial_link_text {
                                text
                            }
                            testimonial_link {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyWhyWorkWithUs {
                        id
                        primary {
                            whyworkwithus_point1 {
                                text
                            }
                            whyworkwithus_point2 {
                                text
                            }
                            whyworkwithus_point3 {
                                text
                            }
                            whyworkwithus_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyUseCases {
                        id
                        items {
                            usecases_usecase {
                                text
                            }
                        }
                        primary {
                            usecases_link_text {
                                text
                            }
                            usecases_title {
                                text
                            }
                            usescases_link {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyTestimonialCarousel {
                        id
                        items {
                            testimonialcarousel_author {
                                text
                            }
                            testimonialcarousel_authorjobtitle {
                                text
                            }
                            testimonialcarousel_image {
                                url
                            }
                            testimonialcarousel_quote {
                                text
                            }
                            testimonialcarousel_title {
                                text
                            }
                        }
                        primary {
                            testimonialcarousel_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyHowItWorksCarousel {
                        id
                        primary {
                            howitworkscarousel_title {
                                text
                            }
                        }
                        items {
                            howitworkscarousel_title {
                                text
                            }
                            howitworkscarousel_summary {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyFeaturedProjects {
                        id
                        primary {
                            featuredprojects_startprojectlink {
                                url
                            }
                            featuredprojects_title {
                                text
                            }
                            display_tag
                        }
                        items {
                            featuredprojects_document {
                                document {
                                    ... on PrismicProject {
                                        id
                                        data {
                                            image {
                                                url
                                            }
                                            summary {
                                                text
                                            }
                                            title {
                                                text
                                            }
                                            industry
                                            service
                                        }
                                        tags
                                    }
                                }
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyServicesCarousel {
                        id
                        items {
                            servicescarousel_image {
                                url
                            }
                            servicescarousel_circleimage {
                                url
                            }
                            servicescarousel_fullscreenimage {
                                url
                            }
                            servicescarousel_link {
                                url
                            }
                            servicescarousel_navigationtitle {
                                text
                            }
                            servicescarousel_summary {
                                text
                            }
                            servicescarousel_title {
                                text
                            }
                        }
                        primary {
                            servicescarousel_subtitle {
                                text
                            }
                            servicescarousel_title {
                                text
                            }
                            servicescarousel_summary {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyFeaturedBlogPosts {
                        id
                        items {
                            featuredblogposts_document {
                                document {
                                    ... on PrismicInsightArticle {
                                        id
                                        data {
                                            listing_image {
                                                url
                                            }
                                            title {
                                                text
                                            }
                                            summary {
                                                text
                                            }
                                        }
                                        url
                                        tags
                                    }
                                    ... on PrismicCustomerStory {
                                        id
                                        data {
                                            listing_image {
                                                url
                                                alt
                                            }
                                            summary {
                                                text
                                            }
                                            title {
                                                text
                                            }
                                        }
                                        url
                                        tags
                                    }
                                }
                                type
                            }
                        }
                        primary {
                            featuredblogposts_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPreregisterDataBodyListing {
                        id
                        primary {
                            listing_title {
                                text
                            }
                            listing_buttonlink {
                                url
                            }
                            listing_buttontext {
                                text
                            }
                            listing_button2link {
                                url
                            }
                            listing_button2text {
                                text
                            }
                        }
                        items {
                            image {
                                url
                            }
                            listing_title {
                                text
                            }
                        }
                        slice_type
                    }
                }
            }
        }
    }
`;

export default withPrismicPreview(PreregisterThankYouAssociates, repositoryConfigs);
